import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import i18n from './i18n';
import { initializeApp } from 'firebase/app';
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyBxZsx26fKlStvTnKOdGEcEIgopIQVLB-U",
  authDomain: "alephbeth-882a4.firebaseapp.com",
  projectId: "alephbeth-882a4",
  storageBucket: "alephbeth-882a4.appspot.com",
  messagingSenderId: "989761399084",
  appId: "1:989761399084:web:3134454abe0af2cf3a2489",
  measurementId: "G-V8QVC6C1LX"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();