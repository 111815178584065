import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { getAnalytics, logEvent } from "firebase/analytics";
import './Home.css';
import appLogo from './assets/app-logo.png';
import appStoreBadge from './assets/app-store-badge-en.svg';
import photo1 from './assets/he_print_aleph.png';
import photo2 from './assets/he_script_aleph.png';
import photo3 from './assets/he_memory_game.png';
import photo4 from './assets/number_two.png';
import photo5 from './assets/he_name.png';
import ipad1 from './assets/ipad1.png';
import ipad2 from './assets/ipad2.png';
import ipad3 from './assets/ipad3.png';
import ipad4 from './assets/ipad4.png';
import ipad5 from './assets/ipad5.png';
import ipad6 from './assets/ipad6.png';
import ipad7 from './assets/ipad7.png';
import ipad8 from './assets/ipad8.png';

function Home() {
    const { t, i18n } = useTranslation();

    const changeLanguage = (lng) => {
        i18n.changeLanguage(lng);
    };

    const isRTL = i18n.dir() === 'rtl';

    const analytics = getAnalytics();

    useEffect(() => {
        const handleClick = () => {
            logEvent(analytics, 'link_click', { name: 'app_store' });
        };
    
        const link = document.getElementById("appStoreLink");
        if (link) {
            link.addEventListener("click", handleClick);
        }
    
        return () => {
            if (link) {
                link.removeEventListener("click", handleClick);
            }
        };
      }, 
      []
    );

    return (
        <div className={`container ${isRTL ? 'rtl' : ''}`}>
            <header className="header">
                <img src={appLogo} alt="App Logo" className="logo" />
                <div className='header-content'>
                    <h1 className='app-title'>{t('appTitle')}</h1>
                    <h2 className='app-subtitle'>{t('appSubtitle')}</h2>
                </div>
            </header>
            <main className='main'>
                <section className='photo-section'>
                    <ul className="photo-list">
                        <li><img src={ipad1} alt="Photo 1" className="large-screen-photo" /></li>
                        <li><img src={ipad2} alt="Photo 2" className="large-screen-photo" /></li>
                        <li><img src={ipad3} alt="Photo 3" className="large-screen-photo" /></li>
                        <li><img src={ipad4} alt="Photo 4" className="large-screen-photo" /></li>
                        <li><img src={ipad5} alt="Photo 5" className="large-screen-photo" /></li>
                        <li><img src={ipad6} alt="Photo 6" className="large-screen-photo" /></li>
                        <li><img src={ipad7} alt="Photo 7" className="large-screen-photo" /></li>
                        <li><img src={ipad8} alt="Photo 8" className="large-screen-photo" /></li>
                        <li><img src={photo1} alt="Hebrew Print Aleph" className="small-screen-photo" /></li>
                        <li><img src={photo2} alt="Hebrew Script Aleph"className="small-screen-photo" /></li>
                        <li><img src={photo3} alt="Hebrew Memory Game" className="small-screen-photo" /></li>
                        <li><img src={photo4} alt="Hebrew Number Two" className="small-screen-photo" /></li>
                        <li><img src={photo5} alt="Hebrew Name" className="small-screen-photo" /></li>
                    </ul>
                </section>
                <div className='container-centered'>
                    <h3>{t('punchline')}</h3>
                    <a href="https://apps.apple.com/il/app/hebrew-alphabet-letters/id1554448029" id='appStoreLink'>
                        <img src={appStoreBadge} alt="Download from the Apple Store" className="badge" />
                    </a>
                </div>
                <p className='description'>{t('description')}</p>
            </main>
            {/* <footer>
                <button onClick={() => changeLanguage('en')}>English</button>
                <button onClick={() => changeLanguage('fr')}>Francais</button>
                <button onClick={() => changeLanguage('he')}>עברית</button>
            </footer> */}
        </div>
    );
}

export default Home;
